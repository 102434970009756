import { useStore } from "vuex";
import { computed , ref , onBeforeMount , watchEffect } from "vue";
const usePaymentsHistory = () => {

  const store = useStore();
  const itemsShow = ref(10)

  const search = async (page = 1) => {
    const { rucOrName } = store.getters["marketing/paymentsHistory"];
    let company = store.getters["user/getInfoUser"].company_name;
    company = company.slice(0, 1).toUpperCase() + company.slice(1);
    await store.dispatch("marketing/search", {
      payload: { rucOrName, company },
      page,
      type: "paymentsHistory",
    });
  };

  const showInfoBehavior = (e,index) => {
    const paymentsBehavior = store.getters["marketing/paymentsHistory"].data.paymentBehavior
    const paymentBehavior = paymentsBehavior[index]
    store.commit("marketing/modalInfoBehavior", { isOpen: true , selected : index  , data : paymentBehavior  });
    const modal_info = document.querySelector(".modal-info");
    const coordenates = e.target.getBoundingClientRect();

    if(window.innerWidth > 992 ){
        itemsShow.value = 10;
        modal_info.style.left = (coordenates.left + 200) + "px";
        modal_info.style.position = "absolute";
        modal_info.style.top = "-52vh";

    }else{
        itemsShow.value = 4;
        modal_info.style.left = "50%";
        modal_info.style.position = "fixed";
        modal_info.style.top =  "50%";
    }
  }

  const changePage = (page) => {
    search(page);
    store.commit("marketing/paymentsHistory", { current_page: page });
  };

  const paymentsHistory = computed(() => {
    return store.getters["marketing/paymentsHistory"];
  });

  const modalInfoBehavior = computed(() => {
    return store.getters["marketing/modalInfoBehavior"];
  });

  const getClass = (info) => {
    const infoData = [];
    infoData.push({ ...info.high, status: "round-payment-red-high" });
    infoData.push({ ...info.medium, status: "round-payment-yellow-medium" });
    infoData.push({ ...info.low, status: "round-payment-green-low" });

    const foundMorePercentage = infoData.sort((a, b) => {
      return Number.parseInt(b.percentage) - Number.parseInt(a.percentage);
    })[0];

    return foundMorePercentage.status;
  };

  onBeforeMount(() => {
    if(window.innerWidth > 992){
        itemsShow.value = 10
    }else{
        itemsShow.value = 4
    }
  })

  watchEffect(() => {
    console.log(window.innerWidth)
  })

  return {
    paymentsHistory,
    modalInfoBehavior,
    itemsShow,
    search,
    getClass,
    showInfoBehavior,
    changePage,
  };
};

export default usePaymentsHistory;
