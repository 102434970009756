<template>
    <div class="scroll mb-3 font_helvetica_neue55_roman">
        <div class="account-info-body mt-3 mx-3 mb-3">
            <div class="header_info">
                <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
                    Ruc
                </h3>
                <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{ data.fc_ruc }}</h3>
            </div>

            <div class="request">
                <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
                    Nombre de usuario
                </h3>
                <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{ data.fc_user_name }}</h3>
            </div>

            <div class="document">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    N° de documento
                </h3>
                <h3 class="mt-1 mb-2">{{ data.document_number }}</h3>
            </div>
            <div class="document-date">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Tipo de documento:
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{ data.document_type }}</h3>
                </div>
            </div>
            <div class="document-date-expire">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Fecha de documento
                </h3>
                <div class="mt-1 mb-2 d-flex justify-between align-items-center">
                    <h3 class="font_helvetica_neue55_roman">{{ (data.document_date ? getFormatDate(data.document_date) :
                            '--') ?? '--'
                    }}</h3>
                </div>
            </div>

            <div class="reference">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Fecha de vencimiento
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{ (data.document_due_date ?
                            getFormatDate(data.document_due_date) : '--') ?? '--'
                    }}</h3>
                </div>
            </div>
            <div class="amount">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Moneda:
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{ data.currency }}</h3>
                </div>
            </div>

            <div class="gui">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Importe:
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{ data.amount }}</h3>
                </div>
            </div>
            <div class="gui">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    N° único
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{ data.number }}</h3>
                </div>
            </div>
            <div class="gui">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Banco
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{ data.bank }}</h3>
                </div>
            </div>
            <div class="d-flex gap-1" v-if="data.document_type !== letterAcepted && data.document_type !== letterPending">
                <button class="
            btn btn-secondary
            p-0
            w-50
            p-1
            d-flex
            align-items-center
            justify-center
          " :disabled="isLoadingDocument" @click="getDocumentMarketing(2, data.document_number.substr(3), data.document_type)">
                    <span class="mr-1 text-white" v-if="!isLoadingDocument">PDF</span>
                    <span class="mr-1 text-white" v-if="isLoadingDocument">...</span>
                    <i class="fas fa-download"></i>
                </button>
                <button class="
            btn btn-secondary
            p-0
            w-50
            p-1
            d-flex
            align-items-center
            justify-center
          " :disabled="isLoadingDocument" @click="getDocumentMarketing(1, data.document_number.substr(3), data?.document_type)">
                    <span class="mr-1 text-white" v-if="!isLoadingDocument">XML</span>
                    <span class="mr-1 text-white" v-if="isLoadingDocument">...</span>
                    <i class="fas fa-download"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import getFormatDate from '../../../../helpers/getFormatDate'
import useStatesAccount from '../../composables/useStatesAccount';
export default {
    props: {
        data: {
            type: Object,
        },
    },
    setup() {
        const { getDocumentMarketing, isLoadingDocument , letterAcepted , letterPending } = useStatesAccount()
        return {
            getFormatDate,
            letterAcepted,
            letterPending,
            getDocumentMarketing,
            isLoadingDocument
        }
    },
}
</script>