<template>
    <div id="dashboard-state-account"  class="scroll p-0 p-relative">
        <h4 class="text-primary mb-1 mt-1">Historial de Protesto</h4>
        <div class="table-stateAccount table-dashboard">
            <div class="
          table-header table-billing-header
          font_helvetica_neue75_bold
          data-header
          data-historical
          header-sticky
        ">
                <div class="table-header-title">
                    <p>Letra</p>
                </div>
                <div class="table-header-title">
                    <p>Mes/Año</p>
                </div>
                <div class="table-header-title">
                    <p>Importe</p>
                </div>
            </div>

            <div class="table-content-scroll stateAccount-billing" v-for="(element, index) in paymentsHistory.data.protestHistory"
                :key="index">
                <div class="table-info data-account data-historical text-center">
                    <div class="table-info-text">
                        <span>{{ element.document_number || '--' }}</span>
                    </div>
                    <div class="table-info-text ">
                        <span>{{ getFormatDate(element.expiration_date) || '--' }}</span>
                    </div>
                    <div class="table-info-text">
                        <p>{{ element.amount  + ' PEN' || '--' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '@/modules/user/styles/style.scss';
@import '@/modules/user/styles/components/dashboard-tables.scss'; //
@import '@/modules/user/styles/pages/dashboard-billing.scss'; //
@import '@/styles/components/_buttons.scss';
#dashboard-state-account{
    max-height: 50vh !important;
    overflow-y: auto;
}
.header-sticky{
  position: sticky;
  top: 0;
  left: 0;
}
</style>


<script>
import usePaymentsHistory from '../../composables/usePaymentsHistory'
import getFormatDate from '../../../../helpers/getFormatDate';
export default {
    components: {
    },
    setup() {
        const { paymentsHistory } = usePaymentsHistory();
        return {
          paymentsHistory,
          getFormatDate
        };
    },
}
</script>