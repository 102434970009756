<template>
    <div id="dashboard-state-account" style="min-height: 50vh;" class=" p-0 p-relative scroll">
        <div class="table-stateAccount table-dashboard">
            <div class="
          table-header table-billing-header
          font_helvetica_neue75_bold
          data-header
          data-letter
          header-sticky
        ">
                <div class="table-header-title xs-none">
                    <p>Ruc</p>
                </div>
                <div class="table-header-title">
                    <p>N° de letra</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>Ref. del girador</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>Fecha de giro</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>Lugar de giro</p>
                </div>
                <div class="table-header-title">
                    <p>Importe</p>
                </div>
                <div class="table-header-title">
                    <p>Fecha de vencimiento</p>
                </div>
                <div class="table-header-title"></div>
            </div>

            <div class="table-content-scroll stateAccount-billing" v-for="(element, index) in digitalLettersSearch.data"
                :key="index">
                <div class="table-info data-account data-letter">
                    <div class="table-info-text nro_doc xs-none">
                        <p>{{ element.ruc  ?? '--' }}</p>
                    </div>
                    <div class="table-info-text">
                        <p>{{ element.letter_number  ?? '--' }}</p>
                        <p :class="element.letter_type === letterPending ? 'pending' : 'accepted'">
                            {{ element.letter_type === letterPending ?  'Letra pendiente' : element.letter_type}}
                        </p>
                    </div>
                    <div class="table-info-text  xs-none">
                        <p>{{ element.ref_giro  ?? '--' }}</p>
                    </div>
                    <div class="table-info-text  xs-none">
                        <p>{{(element.date_giro ?  getFormatDate(element.date_giro) : '--') ?? '--' }}</p>
                    </div>
                    <div class="table-info-text  xs-none">
                        <p>{{ element.place_giro  ?? '--' }}</p>
                    </div>
                    <div class="table-info-text">
                        <p>{{ element.amount  ?? '--' }}</p>
                        <p>{{ 'PEN'  ?? '--' }}</p>
                    </div>
                    <div class="table-info-text">
                        <p>{{ (element.expiration_date ?  getFormatDate(element.expiration_date) : '--') ?? '--' }}</p>
                    </div>
                    <div class="table-info-text d-flex justify-center">
                        <button
                            v-if="element.letter_type === letterPending"
                            class="btn-open btn-other btn-secondary btn-download d-flex gap-0-2 justify-center"
                            @click="getDocumentPDF(element.id)"
                            :disabled="element.isLoadingPDF"
                            >
                            <i class="fas fa-download" v-if="!element.isLoadingPDF"></i>
                            <p class="text-white xs-none" v-if="!element.isLoadingPDF" > PDF </p>
                            <p v-if="element.isLoadingPDF" class="text-white"> ... </p>
                        </button>

                          <button
                            v-if="element.letter_type === letterPending"
                            @click="setInfo(element)"
                            class="btn-open btn-secondary btn-download btn-go d-flex gap-0-2 justify-center"
                            >
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalLayout
        :isShow="
          isShow
        "
      >
        <ModalContainer
          :close="showOrClose"
          :typeModal="typeModal"
          :imageModal="
            messageForm.messageType === 'success'
              ? '/images/decorations/success.png'
              : '/images/decorations/sorry.png'
          "
          :title="
            messageForm.messageType === 'success'
              ? '¡Enhorabuena'
              : 'Lo sentimos'
          "
          :description="messageForm.message"
          :type="messageForm.messageType"
        />
      </ModalLayout>
</template>

<style lang="scss">
@import '@/modules/user/styles/style.scss';
@import '@/modules/user/styles/components/dashboard-tables.scss'; //
@import '@/modules/user/styles/pages/dashboard-billing.scss'; //
@import '@/styles/components/_buttons.scss';
#dashboard-state-account{
    max-height: 50vh !important;
    overflow-y: auto;
}
.header-sticky{
  position: sticky;
  top: 0;
  left: 0;
}
</style>


<script>
import useDigitalLetters from "../../composables/useDigitalLetters";
import ModalLayout from "../../../../layouts/modals/ModalLayout";
import ModalContainer from "../../../../components/ModalContainer";
import getFormatDate from "../../../../helpers/getFormatDate";
export default {
    components: {
        ModalLayout,
        ModalContainer
    },
    props:{
        setInfo: {
            type: Function,
            required: true
        }
    },
    setup() {
        const { digitalLettersSearch, letterAcepted , letterPending , getDocumentPDF , showOrClose , messageForm , typeModal , isShow } = useDigitalLetters();
        return {
            digitalLettersSearch,
            letterPending,
            letterAcepted,
            getDocumentPDF,
            ModalContainer,
            showOrClose,
            messageForm,
            typeModal,
            ModalLayout,
            isShow,
            getFormatDate
        };
    },
}
</script>