<template>
  <div>
    <form class="body" @submit.prevent="loginUser">
      <div class="form-control ml-0 mw-auto">
        <label for="email_address">{{ $t('login.email') }}</label>
        <input
          type="email"
          name="email_address"
          v-model="userForm.email"
          :placeholder="$t('login.emailPlaceholder')"
          required
        />
      </div>
      <div class="form-control mt-2 form-control-custom ml-0 mw-auto">
        <label for="password">{{ $t('login.password') }}</label>
        <div class="input-w-icon input-form">
          <input
            type="password"
            name="password"
            v-model="userForm.password"
            ref="inputPassword"
            :placeholder="$t('login.passwordPlaceholder')"
            required
          />
          <i
            @click="changeType"
            :class="[!showEyesIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"
          ></i>
        </div>
      </div>
      <div class="form-control form-control-check mt-1">
        <input type="checkbox" name="remember-account" v-model="userForm.remind" />
        <label for="remember-account" class="fs-6">{{ $t('login.remember') }}</label>
      </div>

      <LoadingComponent v-if="isLoading" />

      <MessageComponent
        v-if="!isLoading && messageForm.message"
        :message="messageForm.message"
        :messageType="messageForm.messageType"
        :classes="['text-center', 'mt-1', 'form-message']"
      />

      <div class="form-actions mt-1">
        <button type="submit" class="btn btn-secondary">{{ $t('login.title') }}</button>
        <router-link :to="{ name: 'forgot-password' }" @click="gtmRecover()">
          {{ $t('login.forgot') }}
        </router-link>
      </div>
    </form>
    <LoginErrorModal
      v-if="loginModalForm.show"
      :closedModal="resetValues"
      :message="loginModalForm.message"
    />
  </div>
</template>

<script>
import { LoadingComponent, MessageComponent } from '@/components';
import LoginErrorModal from '@/modules/auth/views/login/modals/LoginErrorModal';
import useLogin from '@/modules/auth/composables/login/useLogin';
import useShowPassword from '@/modules/auth/composables/ui/useShowPassword';
import gaFunction from '@/utils/ga/gaFunction';

export default {
  components: {
    LoadingComponent,
    MessageComponent,
    LoginErrorModal,
  },

  setup() {
   
    return {
      ...useLogin(),
      ...useShowPassword(),
    };
  },
  methods:{
    gtmRecover(){
      gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Olvidé mi contraseña'});
    }
  }
};
</script>
