<template>
    <div id="dashboard-state-account" class="scroll p-0 p-relative">
        <div class="table-stateAccount table-dashboard">
            <div class="
          table-header table-billing-header
          font_helvetica_neue75_bold
          data-header
          data-letter
          header-sticky
        ">
                <div class="table-header-title xs-none">
                    <p>Ruc</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>Nombre de usuario</p>
                </div>
                <div class="table-header-title">
                    <p>N° de documento</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>Fecha de documento</p>
                </div>
                <div class="table-header-title">
                    <p>Fecha de vencimiento</p>
                </div>
                <div class="table-header-title">
                    <p>Importe</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>N° único</p>
                </div>
                <div class="table-header-title xs-none">
                    Banco
                </div>

            </div>
            <div class="table-content-scroll stateAccount-billing" v-for="(element, index) in statesAccount.data.data"
                :key="index">
                <div class="table-info data-account data-letter">
                    <div class="table-info-text nro_doc xs-none">
                        <p>{{ element.fc_ruc ?? '--' }}</p>
                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ element.fc_user_name ?? '--' }}</p>

                    </div>
                    <div class="table-info-text">
                        <p>{{ element.document_number ?? '--' }}</p>
                        <p
                            :class="element.document_type === letterPending ? 'pending' : element.document_type === letterAcepted ? 'accepted' : ''">
                            {{ element.document_type === letterPending ? 'Letra pendiente' : element.document_type }}
                        </p>
                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ element.document_date ?? '--' }}</p>
                    </div>
                    <div class="table-info-text">
                        <p>{{ element.document_due_date ?? '--' }}</p>
                    </div>
                    <div class="table-info-text">
                        <p>{{ element.amount ?? '--' }}</p>
                        <p>{{ 'PEN' ?? '--' }}</p>

                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ element.number ?? '--' }}</p>
                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ element.bank ?? '--' }}</p>
                    </div>
                    <div class="table-info-text ">
                        <button @click="setInfo(element)"
                            class="btn-open btn-secondary btn-download btn-go d-flex gap-0-2 justify-center">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                        <div class="d-flex xs-none"
                            v-if="element.document_type !== letterAcepted && element.document_type !== letterPending && element.document_type !== protestedLetter && element.document_type !== ticket && element.document_type !== claim && element.document_type !== balanceFavor && element.document_type !== debitBalance && element.document_type !== advance">
                            <button class="btn btn-secondary btn-download d-flex gap-0-2" :disabled="isLoadingDocument"
                                @click="getDocumentMarketing(2, getDocumentNumber(element.document_number, element.document_type), element.document_type, element.fc_ruc, getInfoUser.company_name)">
                                <i class="fas fa-download"></i>
                                <span class="text-white" v-if="!isLoadingDocument"> PDF </span>
                                <span v-if="isLoadingDocument" class="text-white"> ... </span>
                            </button>
                            <button class="btn btn-secondary btn-download d-flex gap-0-2" :disabled="isLoadingDocument"
                                @click="getDocumentMarketing(1, getDocumentNumber(element.document_number, element.document_type), element.document_type, element.fc_ruc, getInfoUser.company_name)">
                                <i class="fas fa-download"></i>
                                <span class="text-white" v-if="!isLoadingDocument"> XML </span>
                                <span v-if="isLoadingDocument" class="text-white"> ... </span>
                            </button>

                        </div>
                        <button v-if="element.document_type === letterPending"
                            class="btn-open btn-other btn-secondary btn-download d-flex gap-0-2 justify-center"
                            @click="() => getDocumentPDF(element.id)" :disabled="isLoadingDocument">
                            <i class="fas fa-download" v-if="!element.isLoadingPDF"></i>
                            <p class="text-white xs-none" v-if="!element.isLoadingPDF"> PDF </p>
                            <p v-if="element.isLoadingPDF" class="text-white"> ... </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalLayout :isShow="
        isShow
    ">
        <ModalContainer :close="showOrClose" :typeModal="typeModal" :imageModal="
            messageForm.messageType === 'success'
                ? '/images/decorations/success.png'
                : '/images/decorations/sorry.png'
        " :title="
    messageForm.messageType === 'success'
        ? '¡Enhorabuena'
        : 'Lo sentimos'
" :description="messageForm.message" :type="messageForm.messageType" />
    </ModalLayout>
</template>

<style lang="scss">
@import '@/modules/user/styles/style.scss';
@import '@/modules/user/styles/components/dashboard-tables.scss'; //
@import '@/modules/user/styles/pages/dashboard-billing.scss'; //
@import '@/styles/components/_buttons.scss';
#dashboard-state-account{
    max-height: 50vh !important;
    overflow-y: auto;
}
.header-sticky{
  position: sticky;
  top: 0;
  left: 0;
}
</style>


<script>
import useStatesAccount from '../../composables/useStatesAccount';
import getDocumentNumber from '../../../shared/helpers/getDocumentNumber';
import ModalLayout from '../../../../layouts/modals/ModalLayout';
import ModalContainer from '../../../../components/ModalContainer';
export default {
    components: {
        ModalContainer,
        ModalLayout
    },
    props: {
        setInfo: {
            type: Function,
            required: true
        }
    },
    setup() {
        const { statesAccount, letterAcepted, letterPending, creditNote, debitNote, getDocumentMarketing, isLoadingDocument, protestedLetter, ticket, claim, balanceFavor, debitBalance, advance, getInfoUser, getDocumentPDF, isShow, showOrClose, typeModal, messageForm } = useStatesAccount();
        return {
            getDocumentMarketing,
            isShow,
            showOrClose,
            typeModal,
            messageForm,
            getDocumentPDF,
            protestedLetter,
            getInfoUser,
            ticket,
            getDocumentNumber,
            claim,
            balanceFavor,
            debitBalance,
            advance,
            isLoadingDocument,
            statesAccount,
            creditNote,
            debitNote,
            letterAcepted,
            letterPending
        };
    },
}
</script>