<template>
    <DashboardLayout>
        <main class="clients_container">
            <header class="d-flex">
                <h1>Clientes</h1>
                <div class="header-user pointer">
                    <i class="fas fa-user-circle"></i>
                    <p id="name">{{ user.userData.name }}</p>
                </div>
            </header>
            <div class="d-flex top_options">
                <div class="search_box d-flex">
                    <input type="text" placeholder="RUC o Razón social" v-model="search">
                    <i class="fas fa-trash-alt clear_search" v-if="search" @click="clearSearch"></i>
                    <button class="btn btn-primary" @click="searchClients">Buscar</button>
                </div>
                <button class="btn btn-primary-ghost" @click="openCreateModal"><i class="fa fa-plus mr-1"></i>Agregar
                    cliente</button>
            </div>
            <div class="table_container">
                <table v-if="clients" border="0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Código cliente</th>
                            <th>Ruc</th>
                            <th>Razón social</th>
                            <th>Correo</th>
                            <th>Celular</th>
                            <th>Dirección fiscal</th>
                            <th>Zona</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item) in clients.data"
                                :key="item.id">
                            <td>
                                <button class="btn btn-secondary-ghost" @click="openEditModal(item)">
                                    <i class="far fa-edit"></i>
                                </button>
                            </td>
                            <td>{{ item.security_code }}</td>
                            <td>{{ item.ruc }}</td>
                            <td>{{ item.company_name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.cellphone }}</td>
                            <td>{{ item.address }}</td>
                            <td>{{ item.zone }}</td>
                            <td>
                                <button class="btn btn-secondary" @click="viewAsClient(item.id)">
                                    <i class="far fa-eye mr-0_5"></i>
                                    <span>Ver como</span>
                                </button>
                            </td>
                            <td>
                                <button class="btn btn-secondary-ghost" @click="openResetModal(item)">
                                    <i class="fas fa-key mr-0_5"></i>
                                    <span>Resetear contraseña</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="client_pagination" v-if="clients">
                <span><b>Total:</b> {{ clients.total }} clientes</span>
                <div class="table-pagination pagination-table d-flex gap-2 mt-1" v-if="clients.total > 5">
                    <div class="pages">
                        <button class="btn next btn-pagination-admin" @click="changePage(clients.current_page - 1)"
                            v-if="clients.current_page > 1">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <template v-for="n in clients.last_page " :key="n">
                            <button @click="changePage(n)"
                                v-if="n === clients.current_page || n === clients.current_page + 1"
                                :class="`btn btn-pagination-admin ${n === clients.current_page ? 'page-current' : 'next'}`">
                                <span>{{ n }}</span>
                            </button>
                        </template>
                        <span class="suspensives" v-if="clients.current_page < clients.last_page - 2">...</span>
                        <button class="btn next btn-pagination-admin" @click="changePage(clients.last_page)"
                            v-if="clients.current_page < clients.last_page - 1">
                            {{ clients.last_page }}
                        </button>
                    </div>
                    <button class="btn btn-pagination btn-next" @click="changePage(clients.current_page + 1)"
                        v-if="clients.current_page < clients.last_page">
                        <span>Siguiente</span>
                        <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </main>
        <div class="confirm_reset_modal modal" v-if="resetModal">
            <div class="content" v-if="!showFeedback">
                <span class="close_modal" @click="CloseModal"><i class="fas fa-times"></i></span>
                <h3>¿Estás seguro de resetear la contraseña de {{ selectedRow.company_name }}?</h3>
                <LoadingComponent v-if="resetLoading" />
                <div class="options" v-else>
                    <button class="btn btn-primary-ghost" @click="CloseModal">Cancelar</button>
                    <button class="btn btn-primary" @click="resetPassword">Si, resetear</button>
                </div>
            </div>
            <div class="content feedback" v-else>
                <span class="close_modal" @click="CloseModal"><i class="fas fa-times"></i></span>
                <div class="success" v-if="isSuccess">
                    <i class="fas fa-check-circle feedback_icon"></i>
                    <h3>La contraseña de {{ selectedRow.company_name }} se ha reseteado</h3>
                </div>
                <div class="error" v-else>
                    <i class="far fa-frown feedback_icon"></i>
                    <h3>Ocurrió un error, por favor vuelve a intentarlo</h3>
                    <p v-html="errorMessage" class="mt-1"></p>
                </div>
            </div>
        </div>
        <div class="create_client_modal modal" v-if="editModal">
            <div class="content" v-if="!showFeedback">
                <header>
                    <h2>Editar Cliente</h2>
                    <span class="close_modal" @click="CloseModal"><i class="fas fa-times"></i></span>
                </header>
                <form @submit.prevent="updateClient">
                    <div class="fields_grid">
                        <div class="form-group">
                            <label for="code">Código cliente</label>
                            <input type="text" id="code" v-model="selectedRow.security_code" required>
                        </div>
                        <div class="form-group">
                            <label for="Ruc">Ruc</label>
                            <input type="text" id="Ruc" v-model="selectedRow.ruc" required>
                        </div>
                        <div class="form-group">
                            <label for="company">Razón social</label>
                            <input type="text" id="company" v-model="selectedRow.company_name" required>
                        </div>
                        <div class="form-group">
                            <label for="email">Correo electrónico</label>
                            <input type="text" id="email" v-model="selectedRow.email" required>
                        </div>
                        <div class="form-group">
                            <label for="phone">Celular</label>
                            <input type="text" id="phone" v-model="selectedRow.cellphone" >
                        </div>
                        <div class="form-group">
                            <label for="address">Dirección fiscal</label>
                            <input type="text" id="address" v-model="selectedRow.address" >
                        </div>
                        <div class="form-group">
                            <label for="zone">Zona</label>
                            <input type="text" id="zone" v-model="selectedRow.zone" >
                        </div>
                    </div>
                    <LoadingComponent v-if="resetLoading" />
                    <div class="options" v-else>
                        <button class="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
            <div class="content feedback" v-else>
                <span class="close_modal" @click="CloseModal"><i class="fas fa-times"></i></span>
                <div class="success" v-if="isSuccess">
                    <i class="fas fa-check-circle feedback_icon"></i>
                    <h3>Se guardo los cambios</h3>
                </div>
                <div class="error" v-else>
                    <i class="far fa-frown feedback_icon"></i>
                    <h3>Ocurrió un error, por favor vuelve a intentarlo</h3>
                    <p v-html="errorMessage" class="mt-1"></p>
                </div>
            </div>
        </div>
        <div class="create_client_modal modal" v-if="createModal">
            <div class="content" v-if="!showFeedback">
                <header>
                    <h2>Agregar Cliente</h2>
                    <span class="close_modal" @click="CloseModal"><i class="fas fa-times"></i></span>
                </header>
                <div class="import_client_content" v-if="importClients">
                    <input type="file" name="" id="importClientsFile" class="d-none" @change="uploadClients">
                    <LoadingComponent v-if="resetLoading" />
                    <div v-else>
                        <label for="importClientsFile" class="btn btn-primary d-block">Subir clientes</label>
                        <p class="import_clients" @click="downloadTemplate">Descargar plantilla</p>
                    </div>
                </div>
                <form @submit.prevent="createClient" v-else>
                    <div class="fields_grid">
                        <div class="form-group">
                            <label for="code">Código cliente</label>
                            <input type="text" id="code" v-model="newClient.security_code" required>
                        </div>
                        <div class="form-group">
                            <label for="Ruc">Ruc</label>
                            <input type="text" id="Ruc" v-model="newClient.ruc" required>
                        </div>
                        <div class="form-group">
                            <label for="company">Razón social</label>
                            <input type="text" id="company" v-model="newClient.company_name" required>
                        </div>
                        <div class="form-group">
                            <label for="email">Correo electrónico</label>
                            <input type="text" id="email" v-model="newClient.email" required>
                        </div>
                        <div class="form-group">
                            <label for="phone">Celular</label>
                            <input type="text" id="phone" v-model="newClient.cellphone" >
                        </div>
                        <div class="form-group">
                            <label for="address">Dirección fiscal</label>
                            <input type="text" id="address" v-model="newClient.address" >
                        </div>
                        <div class="form-group">
                            <label for="zone">Zona</label>
                            <input type="text" id="zone" v-model="newClient.zone" >
                        </div>
                    </div>
                    <LoadingComponent v-if="resetLoading" />
                    <div class="options" v-else>
                        <button class="btn btn-primary">Guardar</button>
                        <p class="import_clients" @click="importClients = true">Importar desde archivo</p>
                    </div>
                </form>
            </div>
            <div class="content feedback" v-else>
                <span class="close_modal" @click="CloseModal"><i class="fas fa-times"></i></span>
                <div class="success" v-if="isSuccess">
                    <i class="fas fa-check-circle feedback_icon"></i>
                    <h3>Se agrego nuevo cliente</h3>
                </div>
                <div class="error" v-else>
                    <i class="far fa-frown feedback_icon"></i>
                    <h3>Ocurrió un error, por favor vuelve a intentarlo</h3>
                    <p v-html="errorMessage" class="mt-1"></p>
                </div>
            </div>
        </div>
    </DashboardLayout>
    <Footer />
</template>

<style lang="scss">
@import '@/modules/user/styles/style.scss';
@import '@/modules/user/styles/components/dashboard-tables.scss'; //
@import '@/modules/user/styles/pages/dashboard-billing.scss'; //
@import '@/styles/components/_buttons.scss';

#dashboard-state-account {
    max-height: 50vh !important;
    overflow: auto;
}

.header-sticky {
    position: sticky;
    top: 0;
    left: 0;
}

.data-clients {
    grid-template-columns: 102px repeat(6, 1fr) calc(180px + 0.5rem) calc(260px + 0.5rem) !important;
}

.gap-1 {
    gap: 1rem;
}
</style>

<script>
import { onBeforeMount, ref } from 'vue';
import DashboardLayout from '../../../shared/layouts/DashboardLayout';
import Footer from "../../../shared/layouts/Footer";
import Paginator from "../../components/digitalLetters/Paginator";
import { LoadingComponent } from '@/components';
import { useStore, mapState } from 'vuex'

const baseUrl = process.env.VUE_APP_API_DEV;

export default {
    components: {
        DashboardLayout,
        Footer,
        Paginator,
        LoadingComponent
    },
    created() {
        const ref = this
        this.$store.watch(
            function (state) {
                return state.user.company;
            },
            function () {
                ref.getClients()
            },
            {
                deep: true
            }
        );
    },
    setup() {
        const store = useStore();
        const page = ref(1)
        const limit = ref(10)
        const search = ref('')
        const clients = ref(null)

        const getClients = async () => {
            const data = await store.dispatch('admin/getClients', {
                page: page.value,
                limit: limit.value,
                search: search.value
            })
            clients.value = await data
        }

        onBeforeMount(() => {
            getClients()
        })

        const changePage = (newPage) => {
            page.value = newPage
            getClients()
        }

        const resetModal = ref(false)
        const selectedRow = ref(null)
        const resetLoading = ref(false)
        const showFeedback = ref(false)
        const isSuccess = ref(false)
        const errorMessage = ref('')
        const openResetModal = (item) => {
            selectedRow.value = JSON.parse(JSON.stringify(item))
            resetModal.value = true
        }
        const CloseModal = () => {
            selectedRow.value = null
            resetModal.value = false
            isSuccess.value = false
            showFeedback.value = false
            resetLoading.value = false
            editModal.value = false
            createModal.value = false
            importClients.value = false
            errorMessage.value = ''
            newClient.value = {
                "ruc": "",
                "company_name": "",
                "email": "",
                "cellphone": "",
                "address": "",
                "security_code": "",
                "zone": "",
            }
        }

        const resetPassword = async () => {
            resetLoading.value = true
            let value = selectedRow.value.email
            let type = 1
            if (!selectedRow.value.email) {
                value = selectedRow.value.ruc
                type = 2
            }
            const { success, message } = await store.dispatch('auth/forgotPassword', {
                value,
                type,
            });
            resetLoading.value = false
            showFeedback.value = true
            isSuccess.value = success
            errorMessage.value = message
        }

        const editModal = ref(false)
        const openEditModal = (item) => {
            selectedRow.value = JSON.parse(JSON.stringify(item))
            editModal.value = true
        }

        const updateClient = async () => {
            resetLoading.value = true
            const { status, message } = await store.dispatch('admin/updateClient', selectedRow.value);
            await getClients()
            resetLoading.value = false
            showFeedback.value = true
            isSuccess.value = status == 'success'
            console.log(message)
            errorMessage.value = message
        }

        const createModal = ref(false)
        const importClients = ref(false)
        const newClient = ref({
            "ruc": "",
            "company_name": "",
            "email": "",
            "cellphone": "",
            "address": "",
            "security_code": "",
            "zone": "",
        })
        const openCreateModal = () => {
            createModal.value = true
        }

        const createClient = async () => {
            resetLoading.value = true
            const { status, message } = await store.dispatch('admin/createClient', newClient.value);
            await getClients()
            resetLoading.value = false
            showFeedback.value = true
            isSuccess.value = status == 'success'
            errorMessage.value = message.join('<br>')
        }

        const downloadTemplate = async () => {
            const token = localStorage.getItem("x-token") || "";
            try {
                const resp = await fetch(baseUrl + '/user/excel/headings', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        responseType: 'arraybuffer',
                        Authorization: "Bearer  " + token,
                    }
                })
                const blob = await resp.blob();

                // Create a URL for the blob
                const blobUrl = URL.createObjectURL(blob);

                // Create an anchor element
                const anchor = document.createElement('a');
                anchor.href = blobUrl;
                anchor.download = 'clientes_' + new Date().getTime() + '.xlsx'; // Change the filename accordingly
                anchor.style.display = 'none';

                // Append the anchor to the body and click it
                document.body.appendChild(anchor);
                anchor.click();

                // Clean up
                document.body.removeChild(anchor);
                URL.revokeObjectURL(blobUrl);
            } catch (error) {

            }
        }

        const uploadClients = async (e) => {
            const file = e.target.files[0];
            resetLoading.value = true
            const { status, message } = await store.dispatch('admin/uploadClients', file);
            await getClients()
            resetLoading.value = false
            showFeedback.value = true
            isSuccess.value = status == 'success'
            errorMessage.value = message.join('<br>')
        }

        const viewAsClient = async (id) => {
            await store.dispatch('admin/viewAsClient', id)
        }

        const searchClients = async () => {
            page.value = 1
            await getClients()
        }
        const clearSearch = async () => {
            page.value = 1
            search.value = ''
            await getClients()
        }

        return {
            ...store.state,
            page,
            limit,
            search,
            clients,
            changePage,
            getClients,
            resetModal,
            openResetModal,
            selectedRow,
            CloseModal,
            showFeedback,
            isSuccess,
            resetPassword,
            resetLoading,
            editModal,
            openEditModal,
            updateClient,
            newClient,
            createModal,
            openCreateModal,
            createClient,
            importClients,
            downloadTemplate,
            uploadClients,
            viewAsClient,
            errorMessage,
            searchClients,
            clearSearch
        }
    },
    
}
</script>