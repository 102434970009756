<template>
  <DashboardLayout>
    <main class="file-container">
      <div class="file-content">
        <article class="form-content">
          <Header title="Histórico de pagos" />
           <FormSearch />

          <LoadingComponent v-if="paymentsHistory.isLoading" />
          <template v-else>
            <picture class="d-flex justify-center" v-if="Object.entries(paymentsHistory.data).length === 0">
              <img src="/images/decorations/empty3.png" alt="Estado de cuenta" class="empty-image" />
            </picture>
            <template v-else>
              <InfoDetails />
              <BehaviorPayments />
              <TableHistoricalProtest />
            </template>
          </template>
        </article>
      </div>
    </main>
  </DashboardLayout>
  <info-behavior/>
  <Footer/>
</template>

<script>
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import Footer from '@/modules//shared/layouts/Footer';
import InfoBehavior from '../../components/historicalPayments/modals/InfoBehavior';
import Header from '@/modules//shared/layouts/Header';
import LoadingComponent from '../../../../components/LoadingComponent';
import FormSearch from './forms/FormSearch.vue';
import usePaymentsHistory from '../../composables/usePaymentsHistory';
import InfoDetails from '../../components/historicalPayments/InfoDetails';
import TableHistoricalProtest from '../../components/historicalPayments/TableHistoricalProtest';
import BehaviorPayments from '../../components/historicalPayments/BehaviorPayments';
export default{
    components: {
        DashboardLayout,
        Footer,
        InfoDetails,
        BehaviorPayments,
        TableHistoricalProtest,
        Header,
        FormSearch,
        InfoBehavior,
        LoadingComponent
    },
    setup(){
      const { paymentsHistory }  = usePaymentsHistory()
      return {
        paymentsHistory
      }
    }
}

</script>