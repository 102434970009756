import { useStore } from 'vuex'
import { computed , ref , onMounted  } from 'vue'
import { letterAcepted , letterPending , creditNote , debitNote , protestedLetter , ticket , claim , balanceFavor , debitBalance , advance , invoice } from '../../shared/constants/documents'
import useDocument from '../../user/composables/Dashboard/useDocument'
const useStatesAccount = () => {
    const store = useStore()
    const { getDocument } = useDocument()
    const isLoadingDocument = ref(false)
    let isShow = ref(false);
    let typeModal = ref("result");
    let type = ref("success");
    const fileExcel = ref(null)
    const filePdf = ref(null)

  
    const messageForm = ref({
        message: '',
        messageType: '',
    });

    const showOrClose = () =>{
        isShow.value = !isShow.value
      }

    const statesAccount = computed(() => {
        return store.getters["marketing/statesAccount"]
    })
    
    const getInfoUser = computed(() => {
        return store.getters["user/getInfoUser"]
    })

    const setDataSelectState = (data) => {
        store.commit('marketing/statesAccount',{ select : data })
     }
    
    const search = async  (page = 1) => { 
        const { rucOrName } = store.getters["marketing/statesAccount"]
        let company = store.getters["user/getInfoUser"].company_name
        // company = company.slice(0, 1).toUpperCase() + company.slice(1)
        await store.dispatch('marketing/search',{ payload: { rucOrName , company:company } , page , type : "statesAccount" })
    }

    const changePage = (page) => {
        search(page)
        store.commit("marketing/statesAccount", { current_page : page  });
    }

    const getDocumentMarketing = async (type,numberDocument,documentType,ruc,tipoEmpresa) => {
        isLoadingDocument.value = true
        await getDocument(type,numberDocument,documentType,ruc,tipoEmpresa)
        isLoadingDocument.value = false
    }

    const getDocumentPDF = async (idDocument) => {
        isLoadingDocument.value = true
        const { success , fileUrl } = await store.dispatch('admin/getDocument', { id:idDocument })
        if(success){
            let url = new URL(fileUrl);
            let link = document.createElement("a");
            link.setAttribute('target','_BLANK');
            link.setAttribute("href", url.href);
            link.setAttribute("download", "file");
            link.click()
        }else{
            messageForm.value.messageType = 'error';
            isShow.value = true;
            messageForm.value.message = "Datos de letra vacío";
        }
        isLoadingDocument.value = false
    }

    
    return {
        getInfoUser,
        getDocumentPDF,
        balanceFavor,
        debitBalance,
        advance,
        invoice,
        fileExcel,
        filePdf,
        statesAccount,
        isShow,
        setDataSelectState,
        protestedLetter,
        ticket,
        claim,
        letterPending,
        getDocumentMarketing,
        isLoadingDocument,
        creditNote,
        debitNote,
        search,
        messageForm,
        changePage,
        typeModal,
        type,
        showOrClose,
        letterAcepted,
    }
}


export default useStatesAccount;