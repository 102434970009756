<template>
    <div class="behavior-container p-2 mb-1 mt-1">
        <div class="d-flex justify-between behavior-content-header">
            <h4 class="text-primary">Comportamiento de pago</h4>
            <div class="d-flex gap-1">
                <img src="/images/decorations/round.png" alt="Anual">
                <h3 class="text-secondary">Anual</h3>
            </div>
        </div>
        <div class="d-flex behavior-content-legends mt-2 justify-end">
            <div class="d-flex behavior-content-legends-content ">
                <div class="d-flex align-items-center item-legend text-secondary-2">
                    <div class="round round-green"></div>
                    <h6>Riesgo Bajo</h6>
                </div>
                <div class="d-flex align-items-center item-legend text-secondary-2">
                    <div class="round round-yellow"></div>
                    <h6>Riesgo Medio</h6>
                </div>
                <div class="d-flex align-items-center item-legend text-secondary-2">
                    <div class="round round-red"></div>
                    <h6>Riesgo Alto</h6>
                </div>
            </div>
        </div>
        <div class="behavior-content-carousel mt-3">
            <carousel :items-to-show="itemsShow">
                <slide v-for="(element, index) in paymentsHistory.data.paymentBehavior" :key="index">
                    <div class="position-relative">
                        <div class="round-container d-flex justify-center align-items-center pointer" :class="modalInfoBehavior.selected === index ? 'selected-behavior' : ''" @click="e => showInfoBehavior(e,index)">
                            <div class="round-payment " title="Show info" :class="getClass(element)">
                            </div>
                        </div>
                        <h6 v-if="index < paymentsHistory.data.paymentBehavior.length - 1" :style="index === 0 ? 'margin-left:1rem' : ''" class="p-absolute advance">
                        </h6>
                        <h6 style="font-size: 11px;" class="mt-0_5">{{ getFormatDate(element.month + '-' +
                                element.year, 'behavior')
                        }}</h6>
                    </div>
                </slide>
                <template #addons>
                    <navigation  v-if="paymentsHistory.data.paymentBehavior.length > itemsShow" />
                </template>
            </carousel>
        </div>
    </div>
</template>

<script>
import usePaymentsHistory from "../../composables/usePaymentsHistory";
import getFormatDate from "../../../../helpers/getFormatDate";
export default {
    components:{
    },
    setup() {
        const { paymentsHistory, showInfoBehavior, getClass , itemsShow , selected , modalInfoBehavior } = usePaymentsHistory()

        return {
            paymentsHistory,
            selected,
            modalInfoBehavior,
            showInfoBehavior,
            getClass,
            getFormatDate,
            itemsShow,
        }
    }
}
</script>