<template>
  <DashboardLayout>
    <main class="w-100">
      <DropDownContainer className="states-account" :type="'states-account'" :select="'states-account'"
        :showInfo="showInfo" :close="close">
        <AccountInformation :data="statesAccount.select" />
      </DropDownContainer>
      <div class="file-content">
        <article class="form-content">
          <Header title="Estado de cuenta" />
          <FormSearch />
          <LoadingComponent v-if="statesAccount.isLoading" />
          <template v-else>
            <picture class="d-flex justify-center" v-if="statesAccount.data.data.length === 0">
              <img src="/images/decorations/empty.png" alt="Estado de cuenta" class="empty-image" />
            </picture>
            <TableStatesAccount :setInfo="(data) => {
              setDataSelectState(data)
              setInfo('states-account')
            }" v-else />
          </template>
          <div class="d-flex flex-column text-primary mt-1 mb-1" v-if="statesAccount.data.hasOwnProperty('total')" style="align-items: end;">
            <div class="d-block text-center">
              <h5> Descargar todos</h5>
              <div class="d-flex justify-content-end mt-1 gap-1">
                <LoadingComponent v-if="statesAccount.isLoading" />
                <template v-else>
                  <a :href="statesAccount.fileUrlExcel"  v-if="statesAccount.fileUrlExcel" download
                    class="d-flex gap-1 align-items-center btn btn-secondary btn-new decoration-none">
                    <i class="fas fa-file-pdf"></i> EXCEL
                  </a>
                  <a :href="statesAccount.fileUrl" download
                    v-if="statesAccount.fileUrl" class="d-flex gap-1 align-items-center btn btn-secondary btn-new decoration-none">
                    <i class="fas fa-file-pdf"></i> PDF
                  </a>
                </template>
              </div>

            </div>
          </div>
          <Paginator />
        </article>
      </div>
    </main>
  </DashboardLayout>
  <Footer />
</template>

<script>
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import AccountInformation from "@/modules/marketing/components/statesAccount/AccountInformation";
import DropDownContainer from '../../../shared/components/ui/DropDownContainer';
import Footer from '@/modules//shared/layouts/Footer';
import Header from '@/modules//shared/layouts/Header';
import LoadingComponent from '../../../../components/LoadingComponent';
import FormSearch from './forms/FormSearch';
import useStatesAccount from '../../composables/useStatesAccount';
import Paginator from '../../components/statesAccount/Paginator';
import TableStatesAccount from '../../components/statesAccount/TableStatesAccount';
import useDropdown from '../../../../composables/useDropdown';
export default {
  components: {
    DashboardLayout,
    DropDownContainer,
    FormSearch,
    Footer,
    Header,
    LoadingComponent,
    TableStatesAccount,
    Paginator,
    AccountInformation
  },
  setup() {
    const { statesAccount, setDataSelectState } = useStatesAccount()
    const { showInfo, close, setInfo } = useDropdown()
    return {
      statesAccount,
      setDataSelectState,
      showInfo,
      close,
      setInfo
    }
  }
}

</script>