<template>
    <form class="d-flex justify-center my-2 form-table" @submit.prevent="search">
        <input class="input-search border-none" type="text" v-model="paymentsHistory.rucOrName" name="search" placeholder="RUC o Código de cliente" required />
        <div class="next">
            <button class="btn btn-primary btn-next xs-none" type="submit">
                <p>Buscar</p>
            </button>
        </div>
    </form>
</template>


<script>
import usePaymentsHistory from '../../../composables/usePaymentsHistory';
export default {
    setup(){
        const { search , paymentsHistory } = usePaymentsHistory();
        return {
            search,
            paymentsHistory
        }
    }
}  
</script>