<template>
    <DashboardLayout>
        <main class="w-100">
            <DropDownContainer className="digital-letters" :type="'digital-letters'" :select="'digital-letters'" :showInfo="showInfo" :close="close">
                <LetterInformation  :data="digitalLettersSearch.select"/>
            </DropDownContainer>
            <div class="file-content">
                <article class="form-content">
                    <Header title="Letras Digitales" />
                    <FormSearch />
                    <LoadingComponent v-if="digitalLettersSearch.isLoading" />
                    <template v-else>
                        <picture class="d-flex justify-center" v-if="digitalLettersSearch.data.length === 0">
                            <img src="/images/decorations/empty3.png" alt="Letras digitales" class="empty-image" />
                        </picture>
                        <TableDigitalLetters :setInfo="(data) => {
                            setDataSelectLetter(data)
                            setInfo('digital-letters')
                        }" v-else />
                    </template>
                    <Paginator />
                </article>
            </div>
        </main>

    </DashboardLayout>
    <Footer />
</template>

<script>
import DashboardLayout from "../../../shared/layouts/DashboardLayout";
import Paginator from "../../components/digitalLetters/Paginator";
import Footer from "../../../shared/layouts/Footer";
import TableDigitalLetters from "@/modules/admin/components/digitalLetters/TableDigitalLetters";
import Header from "@/modules/shared/layouts/Header";
import FormSearch from "./forms/FormSearch";
import useDigitalLetters from "../../composables/useDigitalLetters";
import LoadingComponent from "../../../../components/LoadingComponent";
import LetterInformation from "../../components/digitalLetters/LetterInformation";
import DropDownContainer from "@/modules/shared/components/ui/DropDownContainer";
import useDropdown from "@/composables/useDropdown";
export default {
    components: {
        DashboardLayout,
        Footer,
        Header,
        DropDownContainer,
        TableDigitalLetters,
        LoadingComponent,
        LetterInformation,
        FormSearch,
        Paginator,
    },
    setup() {
        const { digitalLettersSearch , setDataSelectLetter } = useDigitalLetters()
        const { showInfo , close , setInfo } = useDropdown()
        return {
            digitalLettersSearch,
            showInfo,
            close,
            setDataSelectLetter,
            setInfo
        }
    }
}
</script>