import { useStore } from 'vuex'
import { ref , computed } from 'vue'
import { letterAcepted , letterPending } from '../../shared/constants/documents'
import useDocument from '../../user/composables/Dashboard/useDocument'
const useDigitalLetters = () => {
    const store = useStore()
    const { getDocument  } = useDocument()
    let isShow = ref(false);
    let typeModal = ref("result");
    let type = ref("success");
    const messageForm = ref({
        message: '',
        messageType: '',
    });
    
    const setDataSelectLetter = (data) => {
       store.commit('admin/digitalLettersSearch',{ select : data })
    }

    const search = async  (page = 1) => { 
        const { rucOrName } = store.getters["admin/digitalLettersSearch"]
        let company = store.getters["user/getCompany"]
        console.log(rucOrName,page)
        company = company.slice(0, 1).toUpperCase() + company.slice(1)
        await store.dispatch('admin/search',{ payload: { rucOrName , company } , page })
    }

    const digitalLettersSearch = computed(() =>{
       return store.getters["admin/digitalLettersSearch"]
    })

    const changePage = (page) => {
        search(page)
        store.commit("admin/digitalLettersSearch", { current_page : page  });
    }

    const showOrClose = () =>{
        isShow.value = !isShow.value
    }

    const getDocumentPDF = async (idDocument) => {
        const { data } = store.getters["admin/digitalLettersSearch"]
        const letter = data.find(letter => letter.id === idDocument)
        letter.isLoadingPDF = true
        const { success , fileUrl } = await store.dispatch('admin/getDocument', { id:idDocument })
        letter.isLoadingPDF = false
        if(success){
            let url = new URL(fileUrl);
            let link = document.createElement("a");
            link.setAttribute('target','_BLANK');
            link.setAttribute("href", url.href);
            link.setAttribute("download", "file");
            link.click()
        }else{
            messageForm.value.messageType = 'error';
            isShow.value = true;
            messageForm.value.message = "Datos de letra vacío";
        }

    }


    return {
        setDataSelectLetter,
        changePage,
        showOrClose,
        getDocument,
        getDocumentPDF,
        search,
        isShow,
        typeModal,
        type,
        messageForm,
        digitalLettersSearch,
        letterAcepted,
        letterPending,
    }
}

export default useDigitalLetters;