<template>
  <section class="form">
    <FeaturedColPartForm />
    <article class="form-content form-info">
      <HeaderComponentPartForm
        :title="$t('login.title')"
        :description="$t('login.question')"
        route="register"
        :linkName="$t('login.register')"
        type="auth"
      />
      <LoginForm />
    </article>
  </section>
</template>

<script>
import {
  FeaturedColPartForm,
  HeaderComponentPartForm,
} from '@/modules/auth/components/form-parts';
import LoginForm from '@/modules/auth/views/login/forms/LoginForm';
import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';

export default {
  components: {
    FeaturedColPartForm,
    HeaderComponentPartForm,
    LoginForm,
  },
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('login');
    });
  },
};
</script>
